import { useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import Slider from '../Slider'
import {
    StyledBox,
    StyledDescription,
    StyledSliderBox,
    StyledSpan,
    StyledTitle,
} from './styled'

const ModalContents = ({ project }) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    if (!project) return null

    const { companyName, projectName, description, images, employmentType } =
        project

    const handleSlideChange = (index) => {
        setCurrentIndex(index)
    }

    return (
        <Container
            sx={{ color: '#fff', paddingBottom: '2rem' }}
            disableGutters
            maxWidth={false}
        >
            <StyledTitle variant="h5">{companyName}</StyledTitle>
            <StyledBox>
                <StyledSliderBox>
                    <Slider
                        mediaItem={images}
                        currentIndex={currentIndex}
                        onSlideChange={handleSlideChange}
                    />
                </StyledSliderBox>
                <Box sx={{ mt: { md: 0, xs: '1rem' } }}>
                    <Typography
                        variant="h6"
                        sx={{ fontSize: { xl: '1.5rem', xs: '1.1rem' } }}
                    >
                        Project Name: <StyledSpan>{projectName}</StyledSpan>
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ fontSize: { xl: '1.5rem', xs: '1.1rem' } }}
                    >
                        Employment Type:{' '}
                        <StyledSpan>{employmentType}</StyledSpan>
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: { xl: '1.5rem', xs: '1.1rem' },
                            mt: '1rem',
                        }}
                    >
                        Project Details:
                    </Typography>
                    <StyledDescription
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </Box>
            </StyledBox>
        </Container>
    )
}

export default ModalContents
