import { Box, styled, Typography } from "@mui/material";

export const StyledImg = styled('img')(({ theme }) => ({
    height: '500px',
    borderRadius: '2rem',

    [theme.breakpoints.down('md')]: {
        // width: 'auto',
        // height: '46vw',
        display: 'none'
    },

    [theme.breakpoints.up('xl')]: {
        height: `calc(500px + 25vw - 390px)`
    },
}))

export const StyledAboutContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '2rem',
    margin: '2rem 0',
    flexDirection: 'column',

    [theme.breakpoints.up('xl')]: {
        gap: `calc(3rem + (100vw - 1536px) * 0.01)`,
    },

    [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: 0
    }
}))

export const StyledAbout = styled(Box)(({ theme }) => ({
    border: '0.1rem solid',
    borderColor: theme.palette.textColor.borderColor,
    padding: '1.5rem',
    borderRadius: '2rem',
    textAlign: 'center',
    flex: 1,

    [theme.breakpoints.up('xl')]: {
        padding: `calc(2rem + (100vw - 1536px) * 0.01)`
    },

    [theme.breakpoints.down('sm')]: {
        marginBottom: '1rem'
    }
}))

export const StyledIcon = styled('img')(({ theme }) => ({
    height: '2rem',

    [theme.breakpoints.up('xl')]: {
        height: `calc(3rem + (100vw - 1920px) * 0.01)`
    },
}))

export const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    color: theme.palette.textColor.paragraph,

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`,
    },
}))