import React from 'react'
import {
    StyledAbout,
    StyledAboutContainer,
    StyledIcon,
    StyledText,
} from '../styled'
import { Box, Typography } from '@mui/material'
import { useJsonValues } from '../../../hooks'
import { Link } from 'react-router-dom'

const BioSection = () => {
    const { data } = useJsonValues()
    const { about = [], bio = {} } = data.about

    return (
        <StyledAboutContainer>
            <Box
                sx={{
                    display: { sm: 'flex' },
                    gap: '2rem',
                }}
            >
                {about.map(
                    ({ image, title, experience1, experience2 }, index) => (
                        <StyledAbout key={index}>
                            <StyledIcon src={image} alt="Experience" />
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: {
                                        xl: `calc(1.5rem + (100vw - 1536px) * 0.01)`,
                                        xs: '1.17rem',
                                    },
                                }}
                                fontWeight={600}
                            >
                                {title}
                            </Typography>
                            <StyledText>
                                {experience1}
                                <br />
                                {experience2}
                            </StyledText>
                        </StyledAbout>
                    )
                )}
            </Box>

            <Box
                sx={{
                    textAlign: {
                        sm: 'left',
                        xs: 'justify',
                    },
                    textJustify: 'inter-word',
                    hyphens: ' auto',
                    overflowWrap: -'break-word',
                    lineHeight: 1.5,
                }}
            >
                <StyledText>
                    {bio.bio1}

                    <Box sx={{ mt: '0.5rem' }}>
                        {Array.isArray(bio.bio2) &&
                            bio.bio2.map((item, index) =>
                                typeof item === 'string' ? (
                                    <span key={index}>{item} </span>
                                ) : (
                                    <Link
                                        key={index}
                                        to={item.url}
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#ba1116',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item.text}
                                    </Link>
                                )
                            )}
                    </Box>
                </StyledText>
            </Box>
        </StyledAboutContainer>
    )
}

export default BioSection
