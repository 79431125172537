import { Box, Button, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export const StyledContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '4rem',
    marginTop: '4rem',

    [theme.breakpoints.down('lg')]: {
        display: 'block',
        marginTop: '2rem',
        gap: '2rem'
    }
}))

export const StyledImgContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center'
}))

export const StyledImg = styled('img')(({ theme }) => ({
    height: '400px',
    width: '400px',
    borderRadius: '50%',

    [theme.breakpoints.down('md')]: {
        width: 'auto',
        height: '46vw',
    },

    [theme.breakpoints.only('md')]: {
        width: '275px',
        height: '275px',
    }
}))

export const StyledBox = styled(Box)(({ theme }) => ({
    alignSelf: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('lg')]: {
        marginTop: '2rem'
    }
}))

export const StyledText1 = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.textColor.paragraph,

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.5rem + (100vw - 1536px) * 0.01)`
    },
}))

export const StyledText2 = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 600,

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(2.3rem + (100vw - 1536px) * 0.01)`
    },

    [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
    }
}))

export const StyledText3 = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    fontWeight: 600,
    color: theme.palette.textColor.paragraph,

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.8rem + (100vw - 1536px) * 0.01)`
    },

    [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem'
    }
}))

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    marginTop: '1rem',
}))

export const StyledButton = styled(Button)(({ theme, variant }) => ({
    borderRadius: '2rem',
    borderColor: 'rgb(53, 53, 53)',
    color: variant === 'contained' ? '#fff' : 'rgb(53, 53, 53)',
    backgroundColor: variant === 'contained' ? 'rgb(53, 53, 53)' : '',
    padding: '.8rem 1.2rem',
    transition: 'all 300ms ease',

    '&:hover': {
        color: theme.palette.textColor.whiteColor,
        borderColor: theme.palette.textColor.buttonColor,
        backgroundColor: variant === 'contained' ? theme.palette.textColor.main : theme.palette.textColor.buttonColor,
    },

    [theme.breakpoints.up('xl')]: {
        width: '50%',
        padding: '1rem 2rem',
        fontSize: `calc(.9rem + (100vw - 1536px) * 0.01)`,
        borderRadius: '4rem',

    },

    [theme.breakpoints.down('md')]: {
        padding: '.5rem .8rem',
    }
}))

export const StyledLinkContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    marginTop: '1rem',


    [theme.breakpoints.up('xl')]: {
        gap: '2rem',
        marginTop: '3rem'

    },
}))

export const StyledIcon = styled('img')(({ theme }) => ({
    height: '30px',

    [theme.breakpoints.up('xl')]: {
        height: `calc(40px + (100vw - 1536px) * 0.02)`
    }
}))

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.textColor.whiteColor
}))