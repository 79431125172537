import { Box, Modal } from '@mui/material'
import ModalContents from '../ModalContents'
import { StyledModal } from '../styled'
import CancelIcon from '@mui/icons-material/Cancel'
import React from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import { StyledBox, StyledCancelBox, StyledIcon } from './styled'

const ModalComponent = ({
    open,
    handleClose,
    currentProject,
    nextProject,
    prevProject,
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <StyledModal>
                <StyledBox>
                    <StyledIcon>
                        <ArrowCircleLeftIcon
                            onClick={prevProject}
                            sx={{
                                fontSize: { sm: '50px', xs: '40px' },
                            }}
                        />
                    </StyledIcon>
                    <Box sx={{ flex: 1 }}>
                        <StyledCancelBox>
                            <CancelIcon
                                onClick={handleClose}
                                sx={{
                                    cursor: 'pointer',
                                    color: '#fff',
                                    fontSize: { sm: '40px', xs: '35px' },
                                }}
                            />
                        </StyledCancelBox>
                        <ModalContents project={currentProject} />
                    </Box>
                    <StyledIcon>
                        <ArrowCircleRightIcon
                            onClick={nextProject}
                            sx={{
                                fontSize: { sm: '50px', xs: '40px' },
                                display: { sm: 'block', xs: 'none' },
                            }}
                        />
                    </StyledIcon>
                </StyledBox>
            </StyledModal>
        </Modal>
    )
}

export default ModalComponent
