import { Box } from '@mui/material'
import React from 'react'

const MediaType = ({ type = 'img', src, alt, ...props }) => {
    const mediaTag = {
        img: <img src={src} alt={alt} {...props} />,
        video: <video src={src} {...props} />,
    }

    return (
        <Box
            sx={{
                position: 'relative',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                height: '100%',
            }}
        >
            {mediaTag[type]}
        </Box>
    )
}

export default MediaType
