import { Box } from '@mui/material'
import React from 'react'
import SubHeadings from '../SubHeadings'
import { StyledImg } from './styled'
import { StyledContainer, StyledImgContainer } from '../Profile/styled'
import { useJsonValues } from '../../hooks'
import BioSection from './BioSection'

const About = () => {
    const { data } = useJsonValues()

    const { text, title, image } = data.about

    return (
        <StyledContainer id="about" disableGutters>
            <Box sx={{ mt: '3rem', mx: { xl: '3rem', md: '5rem' } }}>
                <SubHeadings text={text} title={title} />

                <StyledContainer
                    sx={{
                        px: { md: '5rem !important', sm: '2rem', xs: '1rem' },
                        mt: { md: '1rem', sm: 0, xs: '1rem' },
                    }}
                    disableGutters
                >
                    <StyledImgContainer>
                        <StyledImg src={image} alt="Samaila Chatto Bashir" />
                    </StyledImgContainer>

                    <BioSection />
                </StyledContainer>
            </Box>
        </StyledContainer>
    )
}

export default About
