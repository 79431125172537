import { Box, Container } from '@mui/material'
import React, { useState } from 'react'
import SubHeadings from '../SubHeadings'
import {
    StyledBox,
    StyledImg,
    StyledInnerBox,
    StyledTextBox,
    StyledTitle,
} from './styled'
import ModalComponent from './ModalComponent'
import { useJsonValues } from '../../hooks'

const CompanyProject = () => {
    const { data } = useJsonValues()
    const { images = [], projects = [] } = data.companyProjects

    const [open, setOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const slideLength = projects.length

    const handleOpen = (title) => {
        const index = projects.findIndex((proj) => proj.companyName === title)
        setCurrentIndex(index)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setCurrentIndex(0)
    }

    const nextProject = () => {
        setCurrentIndex((prev) => (prev + 1) % slideLength)
    }

    const prevProject = () => {
        setCurrentIndex((prev) => (prev === 0 ? slideLength - 1 : prev - 1))
    }

    return (
        <Container
            id="projects"
            sx={{
                mt: '5rem',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ width: { xl: '100%' } }}>
                <SubHeadings
                    text={'View some of my'}
                    title={'Company Projects'}
                />
                <StyledBox>
                    {images.map(({ src, title }, index) => (
                        <StyledInnerBox key={index}>
                            <StyledImg src={src} alt={`project-${index + 1}`} />
                            <StyledTextBox
                                className="overlay"
                                onClick={() => handleOpen(title)}
                            >
                                <StyledTitle variant="h5">{title}</StyledTitle>
                            </StyledTextBox>
                        </StyledInnerBox>
                    ))}
                </StyledBox>
            </Box>

            <ModalComponent
                open={open}
                handleClose={handleClose}
                currentProject={projects[currentIndex]}
                nextProject={nextProject}
                prevProject={prevProject}
            />
        </Container>
    )
}

export default CompanyProject
