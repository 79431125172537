import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledLink } from './styled'
import { useJsonValues } from '../../../hooks'

const Menu = ({ fontSize, onClick }) => {
    const { data } = useJsonValues()

    const theme = useTheme()
    const isXLarge = useMediaQuery(theme.breakpoints.up('xl'))

    return (
        <>
            {data.navigation.map(({ link, label }, index) => (
                <StyledLink
                    to={link}
                    key={index}
                    target={link.startsWith('http') ? '_blank' : '_self'}
                >
                    <Typography
                        variant={isXLarge ? 'h3' : 'h5'}
                        fontSize={fontSize}
                        onClick={onClick}
                    >
                        {label}
                    </Typography>
                </StyledLink>
            ))}
        </>
    )
}

export default Menu
