import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'block'
    }
}))

export const StyledCancelBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    left: '40px',
    top: 0,

    [theme.breakpoints.down('sm')]: {
        left: '12px',
        top: '-10px',
    }
}))


export const StyledIcon = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    color: '#fff',
    position: 'relative',
    top: 0,
    left: 0,

    [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: '8%',
        left: '5px'
    }
}))