import { useEffect, useState } from "react"

export const useJsonValues = () => {

    const [data, setData] = useState({
        navigation: [],
        profile: {},
        about: {},
        experience: {},
        companyProjects: {},
        project: {},
        contact: {}
    })


    useEffect(() => {
        fetch('/common.json')
            .then((response) => response.json())
            .then((jsonData) => setData(jsonData))
            .catch((error) => console.error('Error fetching data', error))
    }, [])

    return { data }
}