import { useLocation } from 'react-router-dom';
import {
  About,
  CompanyProject,
  Contact,
  Experience,
  Footer,
  Header,
  Profile,
  Projects
} from './components';
import { useEffect } from 'react';

function App() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div>
      <Header />
      <Profile />
      <About />
      <Experience />
      <CompanyProject />
      {/* <Projects /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
