import { Box, Container, styled, Typography } from "@mui/material";

export const StyledBox = styled(Container)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '5rem',
    marginTop: '1rem',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },

    [theme.breakpoints.only('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
    }

}))

export const StyledInnerBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',

    '&:hover .overlay': {
        opacity: 1,
        cursor: 'pointer',
    },

    '&:hover img': {
        filter: 'blur(3px)',
        transform: 'scale(1.1)',
        transition:
            'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
    },

    [theme.breakpoints.down('sm')]: {
        marginTop: '1rem'
    }
}))

export const StyledImg = styled('img')(({ theme }) => ({
    objectFit: 'cover',
    display: 'grid',
    justifyContent: 'center',
    borderRadius: '5px',
    position: 'relative',
    transition:
        'transform 0.3s ease-in-out, filter 0.3s ease-in-out',

    [theme.breakpoints.up('xl')]: {
        width: '100%'
    }
}))

export const StyledTextBox = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    overflow: 'hidden',
    borderRadius: '5px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
}))

export const StyledModal = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#282c34ec',
    border: 'none',
    outline: 'none',
    width: '85%',
    padding: '1rem 1rem 0',

    [theme.breakpoints.down('sm')]: {
        height: '90vh',
        paddingTop: '5rem',
        overflowY: 'auto',
        padding: '1rem 1rem 0'
    },

    [theme.breakpoints.only('sm')]: {
        overflowY: 'auto',
        height: '90vh',

    }
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: "1.3rem",

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.8rem + (100vw - 1536px) * 0.01)`,
    },
}))