import React, { useEffect, useState } from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import MediaType from '../MediaType'
import { StyledBox, StyledIcon, StyledMainBox } from './styled'

const Slider = ({ mediaItem, currentIndex }) => {
    const [currentSlide, setCurrentSlide] = useState(currentIndex)
    const [direction, setDirection] = useState(null)

    const slideLength = mediaItem.length

    const autoScroll = true
    let intervalTime = 5000

    const nextSlide = () => {
        setDirection('right')
        setCurrentSlide((prev) => (prev === slideLength - 1 ? 0 : prev + 1))
    }

    const prevSlide = () => {
        setDirection('left')
        setCurrentSlide((prev) => (prev === 0 ? slideLength - 1 : prev - 1))
    }

    useEffect(() => {
        setCurrentSlide(currentIndex)
        setDirection(null)
    }, [currentIndex])

    useEffect(() => {
        if (autoScroll) {
            const slideInterval = setInterval(nextSlide, intervalTime)
            return () => clearInterval(slideInterval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide])

    return (
        <StyledMainBox>
            <StyledIcon onClick={prevSlide} position={'left'} className="icons">
                <ArrowCircleLeftIcon
                    sx={{ fontSize: { sm: '50px', xs: '40px' } }}
                />
            </StyledIcon>
            {mediaItem.map(({ type, src, alt }, index) => (
                <StyledBox
                    key={index}
                    index={index}
                    currentSlide={currentSlide}
                    direction={direction}
                >
                    {index === currentSlide && (
                        <MediaType
                            src={src}
                            type={type}
                            alt={alt}
                            style={{ width: '100%', height: '140%' }}
                        />
                    )}
                </StyledBox>
            ))}
            <StyledIcon
                onClick={nextSlide}
                position={'right'}
                className="icons"
            >
                <ArrowCircleRightIcon
                    sx={{ fontSize: { sm: '50px', xs: '40px' } }}
                />
            </StyledIcon>
        </StyledMainBox>
    )
}

export default Slider
