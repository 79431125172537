import { Box, styled, Typography } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1.5fr 1fr',
    gap: '1.5rem',
    marginTop: '1rem',
    alignItems: 'flex-start',

    [theme.breakpoints.down('md')]: {
        display: 'block',
        height: '100%', marginTop: '2rem'
    },

    [theme.breakpoints.up('xl')]: {
        height: '100%'
    }
}))

export const StyledSliderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    height: '70%'
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '2rem',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(3rem + (100vw - 1536px) * 0.01)`,
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
    }
}))

export const StyledSpan = styled('span')(({ theme }) => ({
    color: 'rgb(211, 211, 211)',
    fontWeight: 400,

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.3rem + (100vw - 1536px) * 0.01)`,

    }
}))

export const StyledDescription = styled('span')(({ theme }) => ({
    marginTop: 1,
    color: 'rgb(211, 211, 211)',
    whiteSpace: 'pre-line',
    lineHeight: '1.8',

    '& span': {
        display: 'block',
    },

    [theme.breakpoints.up('xl')]: {
        fontSize: '1.3rem'
    },

    [theme.breakpoints.down('sm')]: {
        '& span': {
            display: 'none'
        }
    }
}))