import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Menu from './Menu'
import MobileView from './MobileView'
import { useJsonValues } from '../../hooks'

const Header = () => {
    const { data } = useJsonValues()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

    const menuFontSize = {
        md: '1.5rem',
        xl: `calc(2rem + (80vw - 1536px) * 0.02)`,
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: { sm: '2rem 6rem', xs: '1rem' },
                alignItems: 'center',
            }}
        >
            <img
                src={data.profile.logo}
                alt=""
                width={'60px'}
                height={'70px'}
            />

            {!isMobile ? (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '2rem',
                        alignItems: 'center',
                    }}
                >
                    <Menu fontSize={menuFontSize} />
                </Box>
            ) : (
                <MobileView />
            )}
        </Box>
    )
}

export default Header
