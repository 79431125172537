import { Box, styled } from "@mui/material";

export const StyledMainBox = styled(Box)(() => ({
    '&:hover .icons': {
        opacity: 1,
        cursor: 'pointer'
    }
}))

export const StyledBox = styled(Box)(({ index, currentSlide, theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    transform: index === currentSlide
        ? 'translateX(0)'
        : index < currentSlide
            ? 'translateX(0%)'
            : 'translateX(10%)',
    transition: 'transform 0.5s ease',
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('md')]: {
        position: 'relative',
        transform: index === currentSlide
            ? 'translateX(0)'
            : index < currentSlide
                ? 'translateX(0%)'
                : 'translateX(3%)',
    }

}));

export const StyledIcon = styled(Box)(({ position, theme }) => ({
    color: '#282c34',
    width: '2.5rem',
    height: '2.5rem',
    cursor: 'pointer',
    position: 'absolute',
    top: '200px',
    zIndex: 2,
    [position]: position === 'left' ? '.4rem' : '1rem',
    opacity: 0,
    transition: 'opacity 0.5s ease',

    [theme.breakpoints.up('xl')]: {
        top: '250px'
    },

    [theme.breakpoints.down('sm')]: {
        top: '50px',
        [position]: position === 'left' ? '.4rem' : '.4rem',

    }
}))