import { Box, Button, styled, Typography } from "@mui/material";

export const StyledImg = styled('img')(({ theme }) => ({
    borderRadius: '.5rem',
    width: '35%',
    height: '300px',
    marginBottom: '.5rem',
    marginLeft: '-2rem',

    [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: '1rem',
        height: '200px'
    },

    [theme.breakpoints.only('sm')]: {
        position: 'relative',
        left: '8rem',
        width: '70%'
    },
}))

export const StyledMainBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '3rem',
    justifyContent: 'center',
    borderRadius: '15px',
    width: '80%',
    marginLeft: '8rem',
    marginTop: '2rem',
    padding: '2rem 0 1rem 0',
    backgroundColor: '#F0F1F1',

    [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem',
        width: '90%',
        display: 'block',
        marginBottom: '2rem',
    },

    [theme.breakpoints.only('sm')]: {
        marginLeft: '1rem',
        width: '96%',
        display: 'block',
        marginBottom: '4rem'
    },

    [theme.breakpoints.only('md')]: {
        width: '90%',
        marginLeft: '3rem'
    },

    [theme.breakpoints.up('xl')]: {
        position: 'relative',
        left: `calc(1rem + (100vw - 1536px) * 0.01)`
    }
}))

export const StyledInnerBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        gap: '0.5rem'
    }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '30px',
    boxShadow: 'none',
    backgroundColor: '#2F302C',
    color: '#fff',

    '&:hover': {
        backgroundColor: '#2F302C',
    },

    [theme.breakpoints.down('md')]: {
        fontSize: '.7rem',
        padding: '.5rem',
    },

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`,
    },
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    fontWeight: 600,
    textAlign: 'left',
    width: '70%',
    marginTop: '1.2rem',

    [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        fontSize: '1.6rem',
        width: '100%',
        marginTop: '.5rem'
    },

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(2rem + (100vw - 1536px) * 0.01)`,
    },
}))

export const StyledDesc = styled(Typography)(({ theme }) => ({
    marginTop: '1rem',
    textAlign: 'left',

    [theme.breakpoints.down('md')]: {
        fontSize: '.8rem',
        margin: '0 1rem'
    },

    [theme.breakpoints.only('md')]: {
        fontSize: '1rem',
    },

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`,
    },
}))

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '.7rem',
    marginTop: '1rem',

    [theme.breakpoints.down('md')]: {
        gap: '.5rem',
        marginLeft: '.3rem',
        justifyContent: 'center',
        marginRight: '1rem'
    },

    [theme.breakpoints.only('sm')]: {
        justifyContent: 'center',
        marginRight: '2rem',
        gap: '3rem'
    },

    [theme.breakpoints.only('md')]: {
        justifyContent: 'space-between',
        marginRight: '2rem'
    }
}))

// export const StyledButtonBox = styled(Box)(({ theme }) => ({
//     marginTop: '1.2rem',
//     backgroundColor: '#fff',
//     borderRadius: '20px',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     gap: '1rem',
//     padding: '0 .2rem',
//     width: '33%',

//     [theme.breakpoints.down('sm')]: {
//         gap: '0.3rem',
//         width: '45%'
//     },

//     [theme.breakpoints.between('sm', 'md')]: {
//         width: '30%'
//     }

// }))

// export const StyledIcon = styled('img')(({ theme }) => ({
//     [theme.breakpoints.up('xl')]: {
//         width: `calc(3rem + (100vw - 1920px) * 0.01)`,
//     },
// }))

export const StyledLinkButton = styled(Button)(({ theme }) => ({
    borderRadius: '10px',
    boxShadow: 'none',
    backgroundColor: '#a4db74',
    color: '#222',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    position: 'relative',
    left: '5px',
    fontSize: '.8rem',


    '&:hover': {
        backgroundColor: '#a4db74',
    },

    [theme.breakpoints.down('md')]: {
        fontSize: '.7rem',
    },

    [theme.breakpoints.only('md')]: {
        width: '120px',

    },

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`,
    },
}))
