import { Close, Menu as MenuIcon } from '@mui/icons-material'
import { Box, Drawer, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import Menu from '../Menu'

const MobileView = () => {
    const [openDrawer, setOpenDrawer] = useState(false)

    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false)
                }}
                anchor="right"
                PaperProps={{
                    sx: {
                        padding: '1rem',
                        width: 240,
                        height: 450,
                        backgroundColor: '#fff',
                    },
                }}
            >
                <Typography
                    variant="h5"
                    onClick={() => {
                        setOpenDrawer(!openDrawer)
                    }}
                >
                    {' '}
                    <Close />
                </Typography>

                <Box sx={{ px: '1rem' }}>
                    <Menu
                        fontSize={'h5'}
                        onClick={() => {
                            setOpenDrawer(!openDrawer)
                        }}
                    />
                </Box>
            </Drawer>

            <IconButton
                onClick={() => {
                    setOpenDrawer(!openDrawer)
                }}
            >
                <MenuIcon fontSize="large" />
            </IconButton>
        </>
    )
}

export default MobileView
